import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from "element-ui";
import Fragment from 'vue-fragment'
import "element-ui/lib/theme-chalk/index.css";
import 'normalize.css' //初始化样式
import { Message } from 'element-ui';
import '@/assets/font/font.css'; //字体
import "@/assets/styles/scrollbar.css" //滚动条样式
import directives from '@/utils/preventReClick.js'//防止连点指令v-preventReClick
import MetaInfo from 'vue-meta-info' //预渲染

import '@/permission'

ElementUI.Dialog.props.lockScroll.default = false;
Vue.prototype.$eventBus = new Vue()
Vue.config.productionTip = false
Vue.use(directives) 
Vue.use(ElementUI);
Vue.use(Fragment.Plugin)
Vue.use(MetaInfo)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
